import React, { useState } from "react"
import _ from "lodash"
import { Controller } from "react-hook-form"
import { Checkbox, Input, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react"

import { dayAmount, bookingAnalysis, currencyFormatter } from "../../../util"

function CustomReciptBookingSelect({
  control,
  bookingInfo,
  watch,
  noRoomBookingList,
  setNoRoomBookingList,
  roomBookingList,
  setRoomBookingList,
  serviceList,
  setServiceList,
  rerender,
  setRerender,
}) {
  return (
    <div>
      <div className="font-semibold">รายการจอง</div>
      <Table className="overflow-x-auto" variant="striped">
        <Thead>
          <Tr>
            <Th>เลือก</Th>
            <Th>หมายเลขห้อง</Th>
            <Th>รูปแบบห้องพัก</Th>
            <Th>ราคา</Th>
            <Th>จำนวนคืน</Th>
            <Th>ส่วนลด</Th>
            <Th>ราคาสุทธิ</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.isEmpty(roomBookingList) && (
            <Tr className="">
              <Td colSpan={7}>
                <div className="justify-center text-center">ไม่มีรายการจองระบุห้อง</div>
              </Td>
            </Tr>
          )}

          {_.map(roomBookingList, (eachList, index) => (
            <Tr key={index} className="my-2">
              <Td>
                <Checkbox
                  defaultChecked
                  colorScheme="purple"
                  onChange={(e) => {
                    const tempRoomBookingList = roomBookingList
                    tempRoomBookingList[index].enable = e.target.checked
                    setRoomBookingList(tempRoomBookingList)
                    setRerender(!rerender)
                  }}
                />
              </Td>
              <Td>{eachList?.room?.name}</Td>
              <Td>{eachList?.room?.type?.translation?.th?.name || eachList?.room?.type?.name}</Td>
              <Td>{currencyFormatter.format(eachList?.price)}</Td>
              <Td>{dayAmount(bookingInfo?.start, bookingInfo?.end)} คืน</Td>
              <Td>{eachList?.discount || 0} </Td>
              <Td>
                {currencyFormatter.format(
                  (eachList?.price || 0) * dayAmount(bookingInfo?.start, bookingInfo?.end) -
                    (eachList?.discount || 0) * dayAmount(bookingInfo?.start, bookingInfo?.end),
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <div className="font-semibold my-2">รายการจองไม่ระบุห้อง</div>
      <Table className="overflow-x-auto" variant="striped">
        <Thead>
          <Tr>
            <Th>เลือก</Th>

            <Th>รูปแบบห้องพัก</Th>
            <Th>ราคา</Th>
            <Th>จำนวนห้อง</Th>
            <Th>จำนวนคืน</Th>
            <Th>ส่วนลด</Th>
            <Th>ราคาสุทธิ</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.isEmpty(noRoomBookingList) && (
            <Tr className="">
              <Td colSpan={7}>
                <div className="justify-center text-center">ไม่มีรายการจองไม่ระบุห้อง</div>
              </Td>
            </Tr>
          )}
          {_.map(noRoomBookingList, (eachList, index) => (
            <Tr key={eachList.id} className="my-2">
              <Td>
                <Checkbox
                  defaultChecked
                  colorScheme="purple"
                  onChange={(e) => {
                    const tempNoRoomBookingList = noRoomBookingList
                    tempNoRoomBookingList[index].enable = e.target.checked
                    setNoRoomBookingList(tempNoRoomBookingList)
                    setRerender(!rerender)
                  }}
                />
              </Td>
              <Td>{eachList?.roomtype?.translation?.th?.name || eachList?.roomtype?.name}</Td>
              <Td>{eachList?.price}</Td>
              <Td>{eachList?.amount}</Td>
              <Td>{dayAmount(bookingInfo?.start, bookingInfo?.end)} คืน</Td>
              <Td>{eachList?.discount || 0} </Td>
              <Td>
                {(eachList?.price || 0) * dayAmount(bookingInfo?.start, bookingInfo?.end) -
                  (eachList?.discount || 0) * dayAmount(bookingInfo?.start, bookingInfo?.end)}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>

      <div className="font-semibold my-2">รายการบริการ</div>
      <Table className="overflow-x-auto" variant="striped">
        <Thead>
          <Tr>
            <Th>เลือก</Th>
            <Th>บริการ</Th>
            <Th>ราคาต่อหน่วย</Th>
            <Th>จำนวน</Th>
            <Th>ราคารวม</Th>
            <Th>ห้องพัก</Th>
          </Tr>
        </Thead>
        <Tbody>
          {_.isEmpty(serviceList) && (
            <Tr className="">
              <Td colSpan={6}>
                <div className="justify-center text-center">ไม่มีรายการบริการ</div>
              </Td>
            </Tr>
          )}
          {_.map(serviceList, (eachList, index) => (
            <Tr key={eachList.id} className="my-2">
              <Td>
                <Checkbox
                  defaultChecked
                  colorScheme="purple"
                  onChange={(e) => {
                    const tempServiceList = serviceList
                    tempServiceList[index].enable = e.target.checked
                    setServiceList(tempServiceList)
                    setRerender(!rerender)
                  }}
                />
              </Td>
              <Td>{eachList?.service?.name}</Td>
              <Td>{eachList?.price}</Td>
              <Td>{eachList?.amount}</Td>
              <Td>{currencyFormatter.format((eachList?.price || 0) * (eachList?.amount || 0))} </Td>
              <Td>{eachList?.room?.name || "-"}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <div className="font-semibold my-2">สรุป</div>
      <div>
        <Controller
          name="final_discount"
          control={control}
          defaultValue={bookingInfo?.final_discount}
          render={(field) => (
            <div className="flex gap-2 items-center justify-between">
              <div className="lg:w-1/6">ส่วนลดท้ายบิล</div>
              <Input
                colorScheme="purple"
                onChange={(e) => {
                  field.onChange(e)
                  setRerender(!rerender)
                }}
                size="sm"
              />
              <div className="lg:w-1/6 text-end">บาท</div>
            </div>
          )}
        />
      </div>
      <div className="flex justify-between">
        <div>ราคารวม</div>

        <div className="flex gap-2">
          <div className="text-lg font-semibold">
            {console.log("Room Booking", roomBookingList)}
            {currencyFormatter.format(
              bookingAnalysis({
                ...bookingInfo,
                list: _.filter(roomBookingList, (each) => each?.enable === true),
                no_room_booking: _.filter(noRoomBookingList, (each) => each?.enable === true),
                services: _.filter(serviceList, (each) => each?.enable === true),
                final_discount: parseFloat(watch("final_discount")),
              })?.price,
            )}
          </div>{" "}
          บาท
        </div>
      </div>
      <div className="flex justify-between">
        <div>ราคารวมทั้งการจอง</div>

        <div className="flex gap-2">
          <div className="">{currencyFormatter.format(bookingAnalysis(bookingInfo)?.price)}</div>{" "}
          บาท
        </div>
      </div>
    </div>
  )
}

export default CustomReciptBookingSelect
